import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { filter, lastValueFrom } from 'rxjs';

import { CustomerConfInterface } from '../environments/customer.conf';
import { environment } from '../environments/environment';
import { CustomerModuleKey } from '../models/customer';
import { ApiService } from '../services/api.service';
import { CustomCookieService } from '../services/custom-cookie.service';
import { CustomerService } from '../services/customer.service';
import { DomainConfigService } from '../services/domain-config.service';
import { StyleManager } from '../shared-components';
import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { setStorageObject } from '../utils/storage-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  private document = inject(DOCUMENT);
  public styleManager = inject(StyleManager);
  public apiService = inject(ApiService);
  private authService = inject(AuthService);
  private domainConfig = inject(DomainConfigService);
  private titleService = inject(Title);
  private gtmService = inject(GoogleTagManagerService);
  private router = inject(Router);
  private customerService = inject(CustomerService);
  private customCookieService = inject(CustomCookieService);

  title = 'ec-front';
  config?: CustomerConfInterface;

  async ngOnInit(): Promise<void> {
    const domain = this.apiService.getDomain();
    this.config = this.domainConfig.getConfigForSubdomain(domain);
    this.styleManager.setStyle('theme', `line-${this.config.themeName}.css`);
    if (this.config.firebaseConfig) {
      // Initialize Firebase
      const app = initializeApp(this.config.firebaseConfig);
      // Initialize Analytics and get a reference to the service
      getAnalytics(app);
    }

    this.titleService.setTitle(this.config.socialLoginConfig.euroinnova.name);

    this.document?.getElementById('faviconIco')?.setAttribute('href', this.config.faviconConfig.faviconIco);
    this.document?.getElementById('faviconSvg')?.setAttribute('href', this.config.faviconConfig.faviconSvg);
    this.document?.getElementById('manifest')?.setAttribute('href', this.config.faviconConfig.manifest);
    this.document?.getElementById('appleTouchIcon')?.setAttribute('href', this.config.faviconConfig.appleTouchIcon);

    if (this.config.gtmId) {
      this.gtmService.addGtmToDom();
    }
    const queryParams: { [key: string]: string } = this.parseQueryParamsFromHref(location.href);
    if (queryParams['code']) {
      let url = `/login?code=${queryParams['code']}`;
      let language;
      if (queryParams['lang']) {
        language = queryParams['lang'];
        url += `&lang=${language}`;
      }
      this.router.navigateByUrl(url);
    }

    if (queryParams['token']) {
      setStorageObject(environment.accessTokenStorage, queryParams['token'], 'session');
      this.customCookieService.createUserSessionCookie(queryParams['token'], this.apiService.getSubdomain());
      setStorageObject(environment.supportModeStorage, true, 'session');
      await lastValueFrom(this.authService.fillUserData(true));
      this.router.navigateByUrl('/overview');
    }

    if (this.customerService.isCustomerModuleEnabled(CustomerModuleKey.SALESIQ)) {
      const salesIQScript = this.document.createElement('script');
      salesIQScript.id = 'salesIQScript';
      salesIQScript.type = 'text/javascript';
      salesIQScript.text = 'window.$zoho=window.$zoho || {};$zoho.salesiq=$zoho.salesiq||{ready:function(){}}';

      const zsiqscript = this.document.createElement('script');
      zsiqscript.id = 'zsiqscript';
      zsiqscript.type = 'text/javascript';

      this.customerService.getCustomerModules(false).subscribe((modules) => {
        zsiqscript.src = modules.modules.SALESIQ.config.scriptSrc;
      });

      this.document.head.appendChild(salesIQScript);
      this.document.head.appendChild(zsiqscript);
    }

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const existingCallbellFrame = this.document.querySelector('[data-id="zsalesiq"]');
        const moodleRoute = /^\/course\/\d+\/moodle/;
        const [path] = event.urlAfterRedirects.split('?');

        if (existingCallbellFrame instanceof HTMLElement) {
          existingCallbellFrame.hidden = moodleRoute.test(path);
        }
      });
  }

  parseQueryParamsFromHref(href: string): { [key: string]: string } {
    const rawQueryParams = href.includes('?') ? href.split('?')[1] : '';
    const queryParams: { [key: string]: string } = {};
    rawQueryParams.split('&').forEach((rawQueryParam) => {
      const [key, value] = rawQueryParam.split('=');
      if (!!key && !!value) {
        queryParams[key] = value;
      }
    });

    return queryParams;
  }
}
