export const euroinnovaFrontDomains = [
  'mylxp.euroinnova.com',
  'euroinnova.app.euroinnova.edu.es',
  'euroinnova.eurouat.bi4dev.com',
  'euroinnova.eurodev.bi4dev.com',
  'euroinnova.localhost',
  'mylxp.nucleoo.com'
];

export const inesaludFrontDomains = [
  'mylxp.inesalud.com',
  'inesalud.app.euroinnova.edu.es',
  'inesalud.eurouat.bi4dev.com',
  'inesalud.eurodev.bi4dev.com',
  'inesalud.localhost'
];

export const inesemFrontDomains = [
  'mylxp.inesem.es',
  'inesem.app.euroinnova.edu.es',
  'inesem.eurouat.bi4dev.com',
  'inesem.eurodev.bi4dev.com',
  'inesem.localhost'
];

export const educaFrontDomains = [
  'mylxp.educa.net',
  'educa.app.euroinnova.edu.es',
  'educa.eurouat.bi4dev.com',
  'educa.eurodev.bi4dev.com',
  'educa.localhost'
];

export const edusportFrontDomains = [
  'mylxp.edusport.school',
  'edusport.app.euroinnova.edu.es',
  'edusport.eurouat.bi4dev.com',
  'edusport.eurodev.bi4dev.com',
  'edusport.localhost'
];

export const enaldeFrontDomains = [
  'mylxp.enalde.com',
  'enalde.app.euroinnova.edu.es',
  'enalde.eurouat.bi4dev.com',
  'enalde.eurodev.bi4dev.com',
  'enalde.localhost'
];

export const esibeFrontDomains = [
  'mylxp.esibe.com',
  'esibe.app.euroinnova.edu.es',
  'esibe.eurouat.bi4dev.com',
  'esibe.eurodev.bi4dev.com',
  'esibe.localhost'
];

export const ineafFrontDomains = [
  'mylxp.ineaf.es',
  'ineaf.app.euroinnova.edu.es',
  'ineaf.eurouat.bi4dev.com',
  'ineaf.eurodev.bi4dev.com',
  'ineaf.localhost'
];

export const innovaFrontDomains = [
  'mylxp.innova.com',
  'innova.app.euroinnova.edu.es',
  'innova.eurouat.bi4dev.com',
  'innova.eurodev.bi4dev.com',
  'innova.localhost'
];

export const rededucaFrontDomains = [
  'mylxp.rededuca.net',
  'rededuca.app.euroinnova.edu.es',
  'rededuca.eurouat.bi4dev.com',
  'rededuca.eurodev.bi4dev.com',
  'rededuca.localhost'
];

export const structuraliaFrontDomains = [
  'mylxp.structuralia.com',
  'structuralia.app.euroinnova.edu.es',
  'structuralia.eurouat.bi4dev.com',
  'structuralia.eurodev.bi4dev.com',
  'structuralia.localhost'
];

export const udavinciFrontDomains = [
  'mylxp.udavinci.edu.mx',
  'udavinci.app.euroinnova.edu.es',
  'udavinci.eurouat.bi4dev.com',
  'udavinci.eurodev.bi4dev.com',
  'udavinci.localhost'
];

export const educaopenFrontDomains = [
  'mylxp.educaopen.com',
  'educaopen.app.euroinnova.edu.es',
  'educaopen.eurouat.bi4dev.com',
  'educaopen.eurodev.bi4dev.com',
  'educaopen.localhost'
];

export const universityFrontDomains = [
  'mylxp.app.com',
  'app.educa.university',
  'app.uat.educa.university',
  'app.dev.educa.university',
  'app.localhost',
  'app.eurodev.bi4dev.com',
  'app.eurouat.bi4dev.com',
  'app.app.euroinnova.edu.es'
];

export const ceupeFrontDomains = [
  'mylxp.ceupe.com',
  'ceupe.app.euroinnova.edu.es',
  'ceupe.eurouat.bi4dev.com',
  'ceupe.eurodev.bi4dev.com',
  'ceupe.localhost'
];
